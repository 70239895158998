main { padding-bottom: 70px}

html, body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.container {
  flex: 1;
}
.rating {
  color: #ffbf00;
}
.navbar {
  background-color: #1a1a40;
}
.navbar-brand {
  color: white !important;
  font-weight: bold !important;
}
.navbar-brand:hover, .nav-link:hover {
  opacity: .8;
}
.nav-link {
  color: white !important;
  font-size: 1rem;
  padding: 0 15px;
}
.navbar-nav .nav-item {
  margin: 0 10px;
}
.btn-custom {
  background-color: #1a1a40 !important;
  color: white !important;
}
.btn-primary {
  background-color: #2e2e82;
  border-color: #2e2e82;
}
.btn-primary:hover {
  background-color: #4e4eaa;
  border-color: #4e4eaa;
}
footer {
  background-color: #1a1a40;
  color: white;
  padding: 10px 0;
  width: 100%;
}
.card {
  background-color: #f4f4f6 !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}
.card-img-top {
  height: 200px !important;
  object-fit: cover !important;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.card-title-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.location-header {
  padding: 20px;
  text-align: center;
}
.carousel img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.pros-cons {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.pros, .cons {
  width: 48%;
  background-color: #f4f4f6;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.pros h5, .cons h5 {
  text-align: center;
  color: #2e2e82;
}
.reviews-date {
  font-size: 0.9rem;
  color: gray;
}
.review-count {
  font-size: 1rem;
  color: gray;
  margin-bottom: 20px;
}
.pros p, .cons p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.modal-body {padding: 26px 40px !important}
.modal-open .modal-backdrop {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1 !important;
  }
.success-photo {
    display: block;
    margin-bottom: 10px;
}
.success-photo:last-child {margin-bottom: 0}
a.mb-2:last-child {margin-bottom: 0 !important}
h3.lead {line-height: 1.5}